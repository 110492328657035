import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['imageRevealFriendshipTroubles'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const ImageReveal = makeShortcode("ImageReveal");
const TextCustom = makeShortcode("TextCustom");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "friendship-troubles"
    }}>{`Friendship troubles`}</h1>
    <p>{`Talking about friendship troubles is complex. `}<em parentName="p">{`How can I help?`}</em></p>
    <h3 {...{
      "id": "listen"
    }}>{`Listen`}</h3>
    <p>{`Take the time to listen to your teenager. Talking about friendship troubles can be hard. A few ways to encourage them to open up are some one-on-one time, car drives, texting, or even an email.`}</p>
    <h3 {...{
      "id": "ask-questions"
    }}>{`Ask questions`}</h3>
    <p>{`It’s tempting to just `}<em parentName="p"><strong parentName="em">{`tell`}</strong></em>{` your teenager how to resolve their friendship troubles. But it’s more useful to encourage them to come up with a solution, with your support. Try asking questions in a curious way, to help your teenager problem solve their difficulties. Click on the parent image below to see some suggestions.`}</p>
    <Grid container justify="center" alignItems="center" mdxType="Grid">
  <Grid xs={12} md={6} item mdxType="Grid">
    <ImageReveal id="imageRevealFriendshipTroubles" mainDescription={<TextCustom variant="button" align="center" mdxType="TextCustom">
          Click Image
        </TextCustom>} mainItem={{
          src: '/images/m6/54-01-AUS-version.svg',
          alt: 'main image'
        }} fadeSpeed={2000} aboveReveal={{
          src: '/images/m6/54-all-speech-bubbles_1.12.19.svg',
          alt: 'Thought'
        }} mdxType="ImageReveal" />
  </Grid>
    </Grid>
    <p>{`Some friendship problems can be due to social rejection and bullying. We discuss these in more detail in the rest of this module.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      